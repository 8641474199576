import React from "react";
import { Link } from "gatsby";
import { Title } from "../Core";
import unhavokLogo from "../../assets/images/logos/unhavok-logotype-white.svg"

const Logo = ({ color = "front", height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      <img src={unhavokLogo} alt="unhavok logo" width="110px"/>
    </Link>
  );
};

export default Logo;
